define(['app', '$window', 'siteObj', 'cookie', '$console'], function(app, window, siteObj, cookie, $console) {


  var aboutUsVideo = function() {
    var self = this;
    self.app = app;

    var _config = {
      YouTubeScriptURL: 'https://www.youtube.com/iframe_api',
      playerElementSelector: '[data-js-element=videoPlayer]',
      videoIdSelector: 'data-videoURL',
      youtubeClass: '.youtubeConsentRequired',
      cookieConsentOverlay: '.aboutUsVideo_cookieConsentOverlay',
      youtubeConsentRequiredConfig: '.aboutUsVideo_youtubeConsentRequired'
    };

    var _init = function(element) {
      self.element = element;
      self.app.subscribe('yt_api_ready', self.loadVideo);
      self.youtubeConsent = document.querySelector(self.config.youtubeClass);
      self.youtubeConsentRequiredConfig = document.querySelector(self.config.youtubeConsentRequiredConfig);

      if (!self.youtubeConsent || self.youtubeConsentRequiredConfig) {
        _loadYouTubeAPIScript();
      }
      
      try {
        if (
          cookie.get('actualOptanonConsent') && cookie.get('actualOptanonConsent').includes('C0002') ||
          window.cookieName === 'actualOptanonConsent' && window.cookieValue.includes('C0002') || window.cookieName === 'actualOptanonConsent' && window.cookieValue.includes('2C4')
        ) {
          if(!self.youtubeConsentRequiredConfig) {
            _loadYouTubeAPIScript();
          } else {
            document.querySelector(self.config.playerElementSelector).style.zIndex = "0";
            document.querySelector(self.config.cookieConsentOverlay).style.display = "none";
          }
        } else if (!self.youtubeConsentRequiredConfig) {
            document.querySelector(self.config.playerElementSelector).innerHTML = '<p class="blockedVideoText">' + siteObj.props.videoPlayer.videoBlockedText + '</p>';
        }
      } catch (error) {
        $console.log('OneTrust not Found');
      }
      return self;
    };

    window.onload = () => {
      if(window.OneTrust !== undefined) {
        self.getCookieConsent();
      }else if (!self.youtubeConsentRequiredConfig){
        document.querySelector(self.config.playerElementSelector).innerHTML = '<p class="blockedVideoText">' + siteObj.props.videoPlayer.videoBlockedText + '</p>';
      }
    }

    const _getCookieConsent = () => {
      OneTrust.OnConsentChanged(() => {
        if(OnetrustActiveGroups.includes('C0002') || OnetrustActiveGroups.includes('2C4')) {
          _loadYouTubeAPIScript();
        }
      })
    }

    var _loadYouTubeAPIScript = function() {
      window.onYouTubeIframeAPIReady = function() {
        self.app.publish('yt_api_ready');
      };

      var tag = document.createElement('script');
      tag.src = self.config.YouTubeScriptURL;
      var scriptTag = document.getElementsByTagName('script')[0];
      scriptTag.parentNode.insertBefore(tag, scriptTag);
    };

    var _loadVideo = function() {
      var videoId = _getYouTubeID(self.element.getAttribute(_config.videoIdSelector));
      var playerElement = self.element.querySelector(self.config.playerElementSelector);
      if (videoId && playerElement) {
        self.player = new window.YT.Player(playerElement, {
          width: '100%',
          height: '100%',
          videoId: videoId,
          playerVars: {
            rel: 0,
            modestbranding: 0,
            showinfo: 0
          }
        });
      }
    };

    var _getYouTubeID = function(url) {
      var ID = '';
      url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
      if (url[2] !== undefined) {
        ID = url[2].split(/[^0-9a-z_-]/i);
        ID = ID[0];
      } else {
        ID = url[0];
      }
      return ID;
    };

    self.config = _config;
    self.init = _init;
    self.getYouTubeID = _getYouTubeID;
    self.loadVideo = _loadVideo;
    self.getCookieConsent = _getCookieConsent;
  };

  return aboutUsVideo;
});
